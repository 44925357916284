<template>
  <component
    v-bind="[$props, $attrs]"
    v-on="$listeners"
    :is="component" />
</template>

<script>
  import screenSizeEnum from '@/enums/screenSize.js';
  import { mapGetters } from 'vuex';
  export default {
    computed: {
      ...mapGetters(['getScreenSize']),
      component() {
        switch (this.getScreenSize) {
          case screenSizeEnum.MOBILE:
            return () => import('@/layouts/protected-routes-view/ProtectedRoutesView.mobile.vue');
          case screenSizeEnum.DESKTOP:
            return () => import('@/layouts/protected-routes-view/ProtectedRoutesView.desktop.vue');
          default:
            return () => import('@/layouts/protected-routes-view/ProtectedRoutesView.mobile.vue');
        }
      },
    },
  };
</script>
